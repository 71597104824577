import { createStore } from "vuex";
import NavModule from "./NavModule";
import SwiperModule from "@/store/SwiperModule";
import GalleryModule from "@/store/GalleryModule";
import MenuModule from "@/store/MenuModule";
import EmailModule from "@/store/EmailModule";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    nav: NavModule,
    swiper: SwiperModule,
    gallery: GalleryModule,
    menu: MenuModule,
    email: EmailModule
  }
});
